import { Alert, Box, Button, Card, CardContent, Dialog, IconButton } from '@mui/material';
import React, { useState} from 'react';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const ActivateAccount = ({uid}) => {
    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => true,
        queryParams: {
            station: cookies.station,
            secret: cookies.secret,
            staff: cookies.staff
        },
        onMessage: (message) => handleWebsocketEvent(message),
    });
    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        if (msg.task === 'respConfirmUserEmail' && msg.status === "succeeded") {
            setMessage(msg)
            setLoading(false);
        }

    };

    const handleConfirm = () => {
        setLoading(true);
        sendMessage(JSON.stringify({ task: 'confirmUserEmail', station: cookies.station, staff: cookies.staff, uid }));
    };
    
    return (
        <Card p={2}>
            {!message && <>
            <Alert>Die Emailadresse darf nur manuell bestätigt werden, wenn der Nutzer sicher authentifiziert ist.</Alert>
            <CardContent sx={{gap:2, display:'flex'}}>
                <Button disabled={loading} variant="outlined" onClick={handleConfirm}>E-Mail Adresse bestätigen</Button>
            </CardContent>
            </>}
            {!!message && 
                <CardContent>User aktiviert</CardContent>
            }
        </Card>
    )
};

export default ActivateAccount;

const ActivateAccountButton = ({uid}) => {
    
    const [open, setOpen] = useState(false);
    return (
        <Box>
            <IconButton onClick={()=>setOpen(true)}>
                <NoAccountsIcon color="error" />
            </IconButton>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                {open && <ActivateAccount uid={uid} />}
            </Dialog>
        </Box>
    )
};

export {ActivateAccountButton}