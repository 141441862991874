import { Box } from '@mui/material';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';

import VideoReader from './VideoReader';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const Checkin3 = ({ posConfig }) => {
    const [cookies] = useCookies(['secret', 'station', 'staff']);
    const [kundenData, setKundenData] = useState({});
    const requestCounter = useRef(0); // Counter for generating unique request IDs
    const cache = useRef(new Map()); // Cache to store up to 1000 kdnrs and their results, including empty ones
    const pendingRequests = useRef({}); // Store for managing pending requests by requestId

    const { sendMessage, readyState, lastMessage } = useWebSocket(socketUrl, {
        queryParams: {
            station: cookies.station,
            secret: cookies.secret,
            staff: cookies.staff
        },
        shouldReconnect: (_closeEvent) => true,
        retryOnError: true,
        onClose: () => console.log('closing'),
        onError: (err) => console.log('error', err),
        onReconnectStop: (err) => console.log('recon stop', err),
    });

    // Function to handle WebSocket messages
    const handleWebsocketEvent = (message) => {
        if (!message) return;
        try {
            const parsedMessage = JSON.parse(message.data);
            
            if (parsedMessage.task === 'respCheckKdnrs') {
                const { requestId, result } = parsedMessage;

                if (requestId) {
                    // Cache the results, even if empty
                    for (const kdnr of Object.keys(result.userMap)) {
                        cache.current.set(kdnr, result.userMap[kdnr] || null); // Cache null if the result is empty
                    }

                    // Maintain cache size of up to 1000 entries
                    if (cache.current.size > 1000) {
                        const keys = cache.current.keys();
                        cache.current.delete(keys.next().value); // Delete the oldest entry
                    }

                    // Resolve the promise with the request ID
                    if (pendingRequests.current[requestId]) {
                        pendingRequests.current[requestId].resolve(result.userMap);
                        delete pendingRequests.current[requestId]; // Clean up
                    }
                }
            }
        } catch (error) {
            console.error('Failed to parse WebSocket message:', error);
        }
    };

    // Attach WebSocket message handler
    useEffect(() => {
        if (lastMessage) {
            handleWebsocketEvent(lastMessage);
        }
    }, [lastMessage]);

    // Async getKundenHandler function with cache and unique ID
    const getKundenHandler = useCallback(
        async (kdnrs) => {
            const uncachedKdnrs = kdnrs.filter(kdnr => !cache.current.has(kdnr));

            // If all kdnrs are cached, return the cached data
            if (uncachedKdnrs.length === 0) {
                const cachedResults = {};
                kdnrs.forEach(kdnr => {
                    cachedResults[kdnr] = cache.current.get(kdnr); // Can be data or null
                });
                return cachedResults;
            }

            // Generate a unique request ID for this request
            const requestId = `req-${++requestCounter.current}`;

            return new Promise((resolve, reject) => {
                // Set up a timeout of 2 seconds for the request
                const timeoutId = setTimeout(() => {
                    resolve(false); // Timeout, resolve with false
                    delete pendingRequests.current[requestId]; // Clean up
                }, 2000);

                // Store the promise resolver in pendingRequests
                pendingRequests.current[requestId] = {
                    resolve: (data) => {
                        clearTimeout(timeoutId); // Clear the timeout on success
                        resolve(data); // Resolve with the received data
                    },
                    reject: (error) => {
                        clearTimeout(timeoutId); // Clear the timeout on error
                        reject(error); // Reject the promise
                    }
                };

                // Send the WebSocket message for uncached kdnrs
                sendMessage(
                    JSON.stringify({ 
                        task: 'checkKdnrs', 
                        station: cookies.station, 
                        staff: cookies.staff, 
                        kdnrs: uncachedKdnrs, 
                        requestId // Include the unique request ID
                    })
                );
            });
        },
        [sendMessage, cookies.station, cookies.staff]
    );

    return (
        <Box>
            <VideoReader getKunden={getKundenHandler} />
        </Box>
    );
};

export default Checkin3;
