import { Alert, Box, Button, Card, CardContent, Dialog, IconButton, MenuItem, MenuList, Modal, Select, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { DataGrid, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid';
import useWebSocket from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import { ShipmentHistoryButton } from './Checkout';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PrintIcon from '@mui/icons-material/Print';
import { ActivateAccountButton } from './ActivateAccount';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';

const Explorer = () => {
    return (
        <Routes>
            <Route path="/" element={<ExplorerLayout />}>
                <Route index element={<ObjectsList />} />
                <Route path=":objectKey" element={<ObjectBrowser  />} />
                <Route path=":objectKey/:itemId" element={<ItemBrowser />} />
            </Route>
        </Routes>
    );
};

export default Explorer;

const ExplorerLayout = () => {

    const navigate = useNavigate();
    const {objectKey} = useParams();

    return (
        <>
            <Typography variant="h6">Explorer</Typography>
            <Box sx={{ display: 'flex' }}>
                <Box flex={1} sx={{maxWidth:'150px'}}>
                    <MenuList>
                        {objects.map((e, i) => {
                            return (
                                <MenuItem 
                                    key={i} 
                                    onClick={() => navigate(e.key)} 
                                    value={e.key} 
                                    selected={objectKey === e.key}
                                >
                                    {e.label}
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </Box>
                <Box flex={5}>
                    <Outlet />
                </Box>
            </Box>
        </>
    )
}



const ObjectsList = () => {

    return (
        <Box>
            
        </Box>
    );
};


const columns = [
    {
        field: 'id',
        type: 'number',
        headerName: 'Id',
        description: 'ID',
        sortable: true
    }
];

const columnsWarehouse = [
    {
        field: 'id',
        type: 'number',
        headerName: 'Id',
        width: 100,
        sortable: true
    },
    {
        field: 'text',
        headerName: 'Name',
        width: 120,
        sortable: true
    },{
        field: 'volume',
        headerName: 'Volumen m3',
        width: 120,
        sortable: true
    },
    {
        
        headerName: 'Aktionen',
        sortable: true,
        renderCell: (x)=><Button variant="outlined" size="small" startIcon={<PrintIcon />}>Label</Button>
    }
];


const columnsShipments = [
    {
        field: 'id',
        type: 'number',
        headerName: 'MPS-ID',
        description: 'ID',
        sortable: true,
        renderCell: (x)=><>{x.value} <ShipmentHistoryButton id={x.value} /></>,
        width: 180
    },
    {
        field: 'creationdateAnalytics',
        headerName: 'Eingangsdatum',
        description: 'Name',
        sortable: true,
        type: 'date',
        renderCell: (x)=>{
            let f = new Intl.DateTimeFormat('de', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                hour12: false,
                minute: '2-digit',
                timeZone: 'Europe/Berlin',
                
              });
              return <>{f.format(new Date(x.value))}</>
        },
        width: 180
    },
    /*
    {
        field: 'abholdatum2Analytics',
        headerName: 'Abholdatum',
        description: 'Name',
        type: 'datetime',
        sortable: true
    },
    {
        field: 'abholerID',
        headerName: 'AbholerID',
        description: 'AbholerID',
        sortable: false
    },
    {
        field: 'abholerText',
        headerName: 'Abholer Text',
        description: 'Abholer Text',
        sortable: false
    },
    */
    {
        field: 'spedition',
        headerName: 'Spedition',
        description: 'Spedition',
        sortable: false,
        editable: true,
        width: 100
    },
    {
        field: 'absender',
        headerName: 'Absender',
        description: 'Absender',
        sortable: false,
        width: 120
    },
    {
        field: 'preis',
        headerName: 'Preis',
        description: 'Preis',
        sortable: false,
        width: 80
    },
    {
        field: 'sendungsnummer',
        headerName: 'Sendungsnummer',
        description: 'Sendungsnummer',
        sortable: false,
        width: 220
    },
    {
        field: 'lager_id',
        headerName: 'Lager Id',
        description: 'Lager Id',
        sortable: false
    },
    {
        field: 'klasse',
        headerName: 'Klasse',
        description: 'Klasse',
        sortable: false
    },
    {
        field: 'kdnr',
        headerName: 'Kdnr',
        description: 'Kdnr',
        sortable: false,
    },
    {
        headerName: 'Aktionen',
        renderCell: (x) => <Box><IconButton><DeleteForeverIcon color="error" /></IconButton></Box>
    }
];

const accCellRenderer = (obj) => obj.value === 0 ? <BlockIcon color="error" /> : <CheckCircleOutlineIcon color='success' />

const columnsUsers = [
    {
        field: 'id',
        type: 'number',
        headerName: 'UID',
        width: 60,
        renderCell: ({value})=>value
    },
    {
        field: 'status',
        type: 'number',
        headerName: 'Status',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'vorname',
        headerName: 'Vorname',
        width: 120
    },
    {
        field: 'nachname',
        headerName: 'Nachname',
        width: 120
    },
    {
        field: 'acc1',
        headerName: 'Acc1',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc2',
        headerName: 'Acc2',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc3',
        headerName: 'Acc3',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc4',
        headerName: 'Acc4',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc5',
        headerName: 'Acc5',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc6',
        headerName: 'Acc6',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc7',
        headerName: 'Acc7',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc8',
        headerName: 'Acc8',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc9',
        headerName: 'Acc9',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc10',
        headerName: 'Acc10',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc11',
        headerName: 'Acc11',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc12',
        headerName: 'Acc12',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc13',
        headerName: 'Acc13',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc14',
        headerName: 'Acc14',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc15',
        headerName: 'Acc15',
        width: 40,
        renderCell: accCellRenderer
    },
    {
        field: 'acc16',
        headerName: 'Acc16',
        width: 40,
        renderCell: accCellRenderer
    },
    
];


const columnsCustomers = [
    {
        field: 'id',
        type: 'number',
        headerName: 'Kdnr',
        sortable: true,
        renderCell: (x) => x.value,
        width: 80
    },
    {
        field: 'has_to_be_activated',
        headerName: '',
        description: 'Email-Adresse bestätigt - Account aktiviert',
        sortable: true,
        renderCell: (x) => x.value ? <ActivateAccountButton uid={x.id} />:<IconButton disabled><AccountCircleIcon /></IconButton>,
        width: 40
    },
    {
        field: 'first_name',
        headerName: 'Vorname',
        sortable: true,
        renderCell: (x) => x.value,
        width: 120,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'last_name',
        headerName: 'Nachname',
        sortable: true,
        renderCell: (x) => x.value,
        width: 120,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'user_email',
        headerName: 'E-Mail',
        renderCell: (x) => x.value,
        width: 180,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'mps_birthday',
        type: 'number',
        headerName: 'Geburtstag',
        sortable: true,
        renderCell: (x) => x.value,
        width: 100,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'mps_zip',
        type: 'number',
        headerName: 'PLZ',
        sortable: true,
        renderCell: (x) => x.value,
        width: 60,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'mps_city',
        type: 'number',
        headerName: 'Stadt',
        sortable: true,
        renderCell: (x) => x.value,
        width: 120,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'mps_phone_private',
        type: 'number',
        headerName: 'Telefon (privat)',
        sortable: true,
        renderCell: (x) => x.value,
        width: 150,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
    {
        field: 'mps_company',
        type: 'number',
        headerName: 'Firma',
        sortable: true,
        renderCell: (x) => x.value,
        width: 180,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' || operator.value === '!=',
        ),
    },
];



const objects = [{ label: 'Kunden', key: 'customers', columns: columnsCustomers }, { label: 'Sendungen', key: 'shipments', columns:columnsShipments }, { label: 'Mitarbeiter', key: 'users', columns: columnsUsers }, { label: 'Lagerplätze', key: 'warehouse', columns: columnsWarehouse }]

const ObjectBrowser = () => {

    const { objectKey } = useParams();
    const objectDefinition = objects.filter(e=>e.key===objectKey)?.[0];
    const [cookies] = useCookies(['secret', 'station', 'staff'])
    const [data, setData] = useState({ rows: [], totalCount: 0, columns });
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({filter:[]});
    const [filterModel, setFilterModel] = useState([]);
    const [sort, setSort] = useState();
    const [status, setStatus] = useState(2);

    const [message, setMessage] = useState();
    const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => true,
        queryParams: {
            station: cookies.station,
            secret: cookies.secret,
            staff: cookies.staff
        },
        onMessage: (message) => handleWebsocketEvent(message),
    });
    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        
        if (msg.task === 'respGetExplorerData' && msg.status === "succeeded") {
            setMessage(msg)
        }
    };

    useEffect(() => {
        if (message) {    
            
            setData({
                columns: objectDefinition.columns,
                rows: message.data.rows,
                totalCount: message.data.totalCount,
            });
            setIsLoading(false);
        
        }
    }, [message]);

    const handleFilter = (evt, reason) => {
        const filter = evt.items;
        const linkOperator = evt.linkOperator;
        setFilters({filter, linkOperator})
    };

    const handleSort = (evt) => {
        setSort(evt[0])
    };
    
    const handlePagination = (page) => {
        setOffset(page);
    };
    
    useEffect(()=>{
        setIsLoading(true);
        if(filters.filter.length){
            sendMessage(JSON.stringify({ task: 'getExplorerData', station: cookies.station, staff: cookies.staff, objectKey, filter: filters.filter, linkOperator: filters.linkOperator, offset, sort, status }));
        }else{
            sendMessage(JSON.stringify({ task: 'getExplorerData', station: cookies.station, staff: cookies.staff, objectKey, offset, sort, status}));
        }
    },[filters, offset, sort, status, objectDefinition])

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: "835px", backgroundColor: '#FFF', mb: 10}}>
                <DataToolbar objectKey={objectKey} status={status} setStatus={setStatus} />
                <DataGrid
                    columns={data.columns}
                    rows={data.rows}
                    pagination
                    onPageChange={handlePagination}
                    rowCount={data.totalCount}
                    onFilterModelChange={handleFilter}
                    filterMode='server'
                    paginationMode='server'
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    editMode="cell"
                    sortingMode='server'
                    onSortModelChange={handleSort}
                    loading={isLoading}
                    disableVirtualization={true}
                />
            </Box>
                
        </Box>
    );
};

const DataToolbar = ({objectKey, ...other}) => {


    const getComponent = (objectKey) => {
        switch (objectKey) {
            case 'shipments': return <ToolbarShipments {...other} />
            case 'users': return <ToolbarUsers {...other} />
            case 'warehouse': return <ToolbarWarehouse {...other}/>;
            default: return <Fragment />
        }
        
    };
    return (

        <Card sx={{p:2}}>
            {getComponent(objectKey)}
        </Card>
    );
};

const ToolbarUsers = ({x}) => {
    return (
        <Box><Button variant="outlined">Neuer Mitarbeiter</Button></Box>
    );
};

const ToolbarWarehouse = ({x}) => {
    return (
        <Box><Button variant="outlined">Neuer Lagerplatz</Button></Box>
    );
};

const ToolbarShipments = ({setStatus, status}) => {
    return (
        
            <Select
                onChange={(evt)=>setStatus(evt.target.value)}
                value={status}
                size='small'
            >
                <MenuItem value={2}>Sendungen auf Lager</MenuItem>
                <MenuItem value={3}>Abgeholte Sendungen</MenuItem>
            </Select>
        
    );
};

const ItemBrowser = () => {
    const { objectKey, itemId } = useParams();
    return (
        <Box>
            Object {objectKey} Item {itemId}
        </Box>
    );
};