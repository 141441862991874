import { Lock, LockOpen } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import useWebSocket from 'react-use-websocket';
import WebRTCVideo from './WebRTCVideo';

const useCurrentRegistration = () => {
    const [detectionState, setDetectionState] = React.useState(null);
    const [detectionPipeline, setDetectionPipeline] = React.useState([]);
    const isExecutingPipeline = React.useRef(false);
    const readingsRef = React.useRef({});

    const startDetection = () => {
        const detectionId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        setDetectionState({
            detectionId,
            allSet: false,
            allLocked: false,
            confidence: 0,
            data: {
                missingName: true,
                missingKdnr: true,
                shipmentSize: { value: null, source: null, locked: false, confidence: 0 },
                kdnr: { value: null, source: null, locked: false, confidence: 0 },
                sender: { value: null, source: null, locked: false, confidence: 0 },
                sid: { value: null, source: null, locked: false, confidence: 0 },
                shipmentCompany: { value: null, source: null, locked: false, confidence: 0 },
            },
            count: 0,
        });
        readingsRef.current = {};
    };

    const write = React.useCallback((key, value, source, lock = false) => {
        setDetectionState((prev) => {
            if (!prev) return prev; // No detection in progress
            if (!prev.data[key]) return prev; // INVALID_KEY
            if (prev.data[key].locked && !lock) return prev; // LOCKED

            const newData = { ...prev.data };
            if (
                newData[key].value === value &&
                newData[key].source === source &&
                newData[key].locked === (lock || newData[key].locked)
            ) {
                return prev; // Avoid updating state if nothing has changed
            }
            newData[key] = {
                value,
                source,
                locked: lock || newData[key].locked,
                confidence: 0,
            };

            // Update readings
            const updatedReadings = { ...readingsRef.current };
            if (!updatedReadings[key]) updatedReadings[key] = [];
            updatedReadings[key].unshift(value);
            if (updatedReadings[key].length > 10) updatedReadings[key].pop();
            readingsRef.current = updatedReadings;

            // Update confidence score
            const identicalCount =
                updatedReadings[key]?.filter((reading) => reading === value).length || 0;
            const confidence = identicalCount / 10;
            newData[key].confidence = confidence;
            return { ...prev, count: prev.count + 1, data: newData };
        });
    }, []); // Empty dependency array

    const stopDetection = () => {
        setDetectionState(null);
        readingsRef.current = {};
    };

    const addDetectionMethod = React.useCallback((method) => {
        setDetectionPipeline((prevPipeline) => [...prevPipeline, method]);
    }, []); // Empty dependency array

    const executePipeline = React.useCallback(
        async (message) => {
            if (isExecutingPipeline.current) return;
            isExecutingPipeline.current = true;
            for (const method of detectionPipeline) {
                await method(message, write);
            }

            isExecutingPipeline.current = false;
        },
        [detectionPipeline, write]
    );

    return {
        detectionState,
        startDetection,
        write,
        stopDetection,
        addDetectionMethod,
        executePipeline,
    };
};

const WebSocketComponent = ({ getKunden }) => {
    /*
        const stabilityWindow = new StabilityWindow({
            windowSize: 3,  // 3 Nachrichten müssen übereinstimmen, um Stabilität zu erreichen
            timeout: 10000,  // Timeout nach 2 Sekunden, falls keine Konsistenz gefunden wird
            keys: {
                ocr: {
                    comparisonFn: (a, b) => {
                        if (a.length !== b.length) return false;
    
                        for (let i = 0; i < a.length; i++) {
                            // Vergleiche die 'val'-Eigenschaften
                            if (a[i].val !== b[i].val) return false;
    
                            // Vergleiche 'boundingbox', falls vorhanden
                            if (a[i].boundingbox && b[i].boundingbox) {
                                if (!StabilityWindow.isBoundingboxSimilar(a[i].boundingbox, b[i].boundingbox, 0.05)) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    }
                },
                barcode: { comparisonFn: (a, b) => a === b }
            }
        });
    
        stabilityWindow.on('stable', (result) => {
            console.log('Stabiles Ergebnis gefunden:', result.stableMessage);
        });
    
        stabilityWindow.on('timeout', () => {
            console.log('Zeitüberschreitung: Keine Stabilität gefunden.');
        });
    */


    const {
        detectionState,
        startDetection,
        write,
        stopDetection,
        addDetectionMethod,
        executePipeline,
    } = useCurrentRegistration();
    const { lastMessage } = useWebSocket('ws://localhost:8765', {
        onOpen: () => console.log('WebSocket connection established'),
        onClose: () => console.log('WebSocket connection closed'),
        onError: (error) => console.error('WebSocket error:', error),
        shouldReconnect: () => true, // Reconnect on disconnection
    });

    React.useEffect(() => {
        // Add Kdnr detection method to the pipeline
        addDetectionMethod(async (message, write) => {
            let matchedPostfachnummer = false;
            let nameMatched = false;
            let potentialKunden = [];

            if (message.data?.textlines) {
                for (const line of message.data.textlines) {
                    const erkannterText = line.text;
                    const postfachPattern = /[^\d](\d{5})\b|\b(\d{5})\b/;
                    const postfachMatches = erkannterText.match(postfachPattern);
                    const matchedId = postfachMatches?.[1] || postfachMatches?.[2];

                    if (matchedId) {
                        potentialKunden.push(matchedId);
                    }
                }
            }


            const kunden = await getKunden(potentialKunden);

            for (const kdnr of Object.keys(kunden)) {
                const firstname = kunden[kdnr].first_name;
                const lastname = kunden[kdnr].last_name;
                if (!(lastname && firstname)) {
                    console.log('no name', kdnr)
                    continue;
                }
                for (const line of message.data.textlines) {
                    let matched = [];

                    if (new RegExp(`[^\\d]?${kdnr}\\b`).test(line.text)) {
                        matched.push('postfachnummer');  // Add 'postfachnummer' to matched array
                        matchedPostfachnummer = kdnr;
                    }
                    // If the detection contains the matched postbox number, mark it
                    if (new RegExp(`.*${createAccentInsensitiveRegex(firstname.replace('-', '\-'))}.{1,10}${createAccentInsensitiveRegex(lastname.replace('-', '\-'))}.*`, 'i').test(line.text)) {

                        nameMatched = true;
                        matched.push('name');  // Add 'name' to matched array
                    }

                    // If the detection contains the matched postbox number, mark it
                    if (new RegExp(`.*${createAccentInsensitiveRegex(lastname.replace('-', '\-'))}.{1,10}${createAccentInsensitiveRegex(firstname.replace('-', '\-'))}.*`, 'i').test(line.text)) {
                        nameMatched = true;
                        matched.push('name');  // Add 'name' to matched array
                    }

                }
                console.log({ nameMatched, matchedPostfachnummer })
                if (matchedPostfachnummer && nameMatched) {
                    break;
                }
            }
            console.log(kunden)

            const isValid = nameMatched ? matchedPostfachnummer : false;
            if (isValid) {
                write('kdnr', matchedPostfachnummer, 'OCR/DB', true);
            } else {
                console.log('INVALID')
            }
        });

        addDetectionMethod(async (message, write) => {
            console.log({ message })
            let predictions = [];
            if (message.data?.barcodes) {
                for (const line of message.data.barcodes) {
                    const barcode = line.data;
                    const temp = predictDeliveryCompany(barcode);
                    if (temp.length) {
                        for (const prediction of temp) {
                            predictions.push({ shipmentCompany: prediction, sid: barcode, source: 'barcode' });
                        }
                    };
                }
            }
            if (message.data?.datamatrix) {
                for (const line of message.data.datamatrix) {
                    const datamatrix = line.data;
                    const temp = predictDeliveryCompany(datamatrix);
                    if (temp.length) {
                        for (const prediction of temp) {
                            predictions.push({ shipmentCompany: prediction, sid: datamatrix, source: 'datamatrix' });
                        }
                    };
                }
            }

            if (predictions.length === 1) {
                write('sid', predictions[0].sid, predictions[0].source, true);
                write('shipmentCompany', predictions[0].shipmentCompany, predictions[0].source, true);
                if ("Amazon" == predictions[0].shipmentCompany) {
                    write('sender', predictions[0].shipmentCompany, predictions[0].source, true);
                }
            } else {
                console.log({ predictions })
            }


        });

    }, []); // Empty dependency array

    React.useEffect(() => {
        if (lastMessage !== null) {
            const newMessage = JSON.parse(lastMessage.data);
            if (newMessage && detectionState) {
                console.log({ newMessage })
                /*
                stabilityWindow.addMessage({
                    ocr: (newMessage.textlines||[]).map(e=>({val: e.data, boundingbox: e.boundingBox})),
                    barcode: (newMessage.barcodes||[]).map(e=>({val: e.data})),
                    datamatrix: (newMessage.datamatrix||[]).map(e=>({val: e.data})),
                });
                */
                executePipeline(newMessage);
            }
        }
    }, [lastMessage, executePipeline, detectionState]);


    const statusFields = ['shipmentCompany', 'kdnr', 'sender', 'sid', 'shipmentSize'];

    return (
        <div>
            <h2>Current Detection State</h2>

            <button onClick={startDetection}>Start Detection</button>
            <button onClick={stopDetection}>Stop Detection</button>




            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Current Detection State
                </Typography>
                {detectionState ? (
                    <>
                        <Grid container spacing={2}>
                            {statusFields.map((key) => {
                                const field = detectionState.data[key];
                                const isLocked = field.locked;
                                const value = field.value;
                                const source = field.source;

                                return (
                                    <Grid item xs={12} sm={6} md={4} key={key}>
                                        <Paper sx={{ p: 2 }}>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="h6" sx={{ flexGrow: 1, textTransform: 'capitalize' }}>
                                                    {key.replace(/([A-Z])/g, ' $1')}
                                                </Typography>
                                                {isLocked ? (
                                                    <Lock sx={{ color: 'green' }} />
                                                ) : value === null ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    <LockOpen sx={{ color: 'grey' }} />
                                                )}
                                            </Box>
                                            <Typography variant="body1">
                                                {value !== null ? value : 'No value'}
                                            </Typography>
                                            {source && (
                                                <Chip label={source} variant="outlined" sx={{ mt: 1 }} />
                                            )}
                                        </Paper>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={startDetection}>
                                Start Detection
                            </Button>
                            <Button variant="contained" onClick={stopDetection} sx={{ ml: 1 }}>
                                Stop Detection
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="body1">No active detection</Typography>
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={startDetection}>
                                Start Detection
                            </Button>
                        </Box>
                    </>
                )}
                <WebRTCVideo />
                {detectionState ? (
                    <pre>{JSON.stringify(detectionState, null, 2)}</pre>
                ) : (
                    <p>No active detection</p>
                )}
            </Box>


        </div>
    );
};

export default WebSocketComponent;


const predictDeliveryCompany = (shipmentNumber) => {

    const rules = [
        { name: 'Hermes', rule: /^(3S[A-Z0-9]{11}|[0-9]{14}|H[0-9]{19})$/ },
        { name: 'Trans-o-Flex', rule: /^[0-9]{24}$/ },
        { name: 'FedEx', rule: /^([0-9]{28}|[0-9]{34})$/ },
        { name: 'Post', rule: /^0034[0-9]{16}$/ },
        { name: 'DPD', rule: /^[0-9]{15}$/ },
        { name: 'Post', rule: /^([A-F0-9]{20}|[A-Z0-9]{11}[A-Z]{2})$/ },
        { name: 'GLS', rule: /^([0-9]{12}|[YZ][A-Z0-9]{7})$/ },
        { name: 'UPS', rule: /^1Z[A-Z0-9]{16}$/ },
        { name: 'Amazon', rule: /^DE[0-9]{9,10}$/ },
        { name: 'DHL Express', rule: /^[0-9]{10}$/ },
        { name: 'DHL', rule: /^([0-9]{12}|JVGL.+|0034.+|\(00\)34.+|JJ.+)$/ }
    ];

    let results = [];
    for (let i = 0; i < rules.length; i++) {
        const company = rules[i].name;
        const regex = rules[i].rule;
        if (regex.test(shipmentNumber)) {
            results.push(company);
        }
    };

    return results;
}








// Mapping of characters to their accented variants
const accentMap = {
    "a": ["á", "à", "â", "ä", "ã", "å", "ā"],
    "e": ["é", "è", "ê", "ë", "ē", "ė", "ę"],
    "i": ["í", "ì", "î", "ï", "ī", "į", "l"],
    "o": ["ó", "ò", "ô", "ö", "õ", "ø", "ō"],
    "u": ["ú", "ù", "û", "ü", "ū"],
    "c": ["ç", "ć", "č"],
    "n": ["ñ", "ń"],
    "y": ["ý", "ÿ"],
    "s": ["ś", "š"],
    "z": ["ź", "ż", "ž"],
    "g": ["ĝ", "ğ"]
};

// Function to normalize accented characters
function normalizeChar(char) {
    const lowerChar = char.toLowerCase();

    // Check if the character is in the accent map or one of its variants
    for (const baseChar in accentMap) {
        if (baseChar === lowerChar || accentMap[baseChar].includes(lowerChar)) {
            // Return regex group containing both the base character and its accented variants
            return `[${baseChar}${accentMap[baseChar].join('')}]`;
        }
    }
    return char; // Return the character itself if it's not in the map
}

// Function to create the regex string
function createAccentInsensitiveRegex(str) {
    return str
        .split('') // Split the string into individual characters
        .map(normalizeChar) // Normalize each character (accounting for accents)
        .join(''); // Join back into a string
}

