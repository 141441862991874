import { Alert, Box, Button, Card, CardActionArea, Checkbox, Chip, CircularProgress, Drawer, Fab, IconButton, Paper, Slide, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CheckinLayout2, LabelPreview, LastShipments, SearchField, SidScannerField } from './Checkin';
import Webcam from 'react-webcam';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import useWebSocket from 'react-use-websocket';
import ImageWithPolygons from './CheckinImageWithPolyon';

import upsLogo from '../speditionen/ups.png';
import dhlLogo from '../speditionen/dhl.png';
import dpdLogo from '../speditionen/dpd.png';
import hermesLogo from '../speditionen/hermes.png';
import amazonLogo from '../speditionen/amazon.png';
import dhlexpressLogo from '../speditionen/dhlexpress.png';
import fedexLogo from '../speditionen/fedex.png';
import glsLogo from '../speditionen/gls.png';
import postLogo from '../speditionen/post.png';
import transoflexLogo from '../speditionen/transoflex.png';
import { Cancel, History } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import VideoReader from './VideoReader';
import Checkin3 from './Checkin3';

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';
const restUrl = 'https://2zl7yth6pi.execute-api.eu-central-1.amazonaws.com/prod';

const Checkin2 = ({ posConfig }) => {

    return (
        <Routes>
            <Route path="/" element={<CheckinLayout2 />}>
                <Route index element={<CheckinShipment posConfig={posConfig} />} />
                <Route path=":customerId" element={<EditShipment posConfig={posConfig} />} />
                <Route path='demo' element={<Checkin3 />} />
            </Route>
        </Routes>
    );
};

export default Checkin2;

const EditShipment = () => { };

const CheckinShipment = ({ posConfig }) => {

    const [cookies, setCookie] = useCookies(['secret', 'station', 'staff']);

    const [previewImage, setPreviewImgage] = useState(null);
    const [responseImage, setResponseImage] = useState(null);
    const [responseS3Key, setResponseS3Key] = useState(null);
    const [pendingAnalysis, setPendingAnalysis] = useState(false);
    const [detectionResults, setDetectionResults] = useState(null);
    const [mainScreen, setMainScreen] = useState('OVERVIEW');
    const [checkinData, setCheckinData] = useState({});
    const [checkSidResponse, setCheckSidResponse] = useState(null);
    const [lastLabel, setLastLabel] = useState(false);
    const [detectedBarcodes, setDetectedBarcodes] = useState([]);
    const [feedback, setFeedback] = useState(null);
    const [manualEditedData, setManualEditedData] = useState({});

    const reset = () => {
        setPreviewImgage(null);
        setResponseImage(null);
        setPendingAnalysis(false);
        setDetectionResults(null);
        setMainScreen('OVERVIEW');
        setCheckinData({ spedition: checkinData.spedition });
        setCheckSidResponse(null);
        setResponseS3Key(null);
        setDetectedBarcodes([]);
        setFeedback(null);
        setManualEditedData({});
    };

    const webcamRef = useRef(null);
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setPreviewImgage(imageSrc);
            console.time('x')
        },
        [webcamRef]
    );

    /*
    useEffect(() => {
        const uploadImage = async (img) => {

            return await axios.post(restUrl + '/checkoutapp/checkinimage', {
                image: img,
            }, {
                headers: {
                    authorization: cookies.secret
                }
            });

        }
        const run = async (img) => {
            const { data } = await uploadImage(img);
            const { imageUrl, s3Key } = data;
            setResponseImage(imageUrl);
            setPendingAnalysis(true);
            setResponseS3Key(s3Key);
            detectLabelAnalysis(s3Key);
            console.log({ data });
        };
        if (previewImage) {
            run(previewImage);
        }
    }, [previewImage, cookies.secret])
*/

useEffect(() => {
    const getSignedUrl = async (file) => {
        // Step 1: Get the presigned URL from Lambda
        const { data } = await axios.post(restUrl + '/checkoutapp/checkinimage', {
            fileName: file.name,
            fileType: file.type,
        }, {
            headers: {
                authorization: cookies.secret,
            }
        });
 
        return data; // { uploadURL, s3Key }
    };

    const uploadImageToS3 = async (url, file) => {
        // Step 2: Upload the image directly to S3 using the presigned URL
        return await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
    };
    const base64ToBlob = (base64Data, contentType = '') => {
        const byteCharacters = atob(base64Data.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    };
    const run = async (img) => {
        try {
            // Step 1: Get the presigned URL and s3Key
            const { uploadURL, imageUrl, s3Key, thumbUrl } = await getSignedUrl(img);

            // Step 2: Upload the image to S3
            const x=await uploadImageToS3(uploadURL, base64ToBlob(img));
            console.log({x});

            // Step 3: Once uploaded, save the s3Key and call further analysis
            setResponseS3Key(s3Key);
            setResponseImage(previewImage);
            setPendingAnalysis(true);

            // Step 4: Perform label detection or other analysis using the S3 key
            detectLabelAnalysis(s3Key);
        } catch (error) {
            console.error("Image upload failed:", error);
        }
    };

    if (previewImage) {
        run(previewImage);
    }
}, [previewImage, cookies.secret]);



    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
            queryParams: {
                station: cookies.station,
                secret: cookies.secret,
                staff: cookies.staff
            },
            onMessage: (message) => handleWebsocketEvent(message),
            shouldReconnect: (_closeEvent) => true,
            retryOnError: true,
            onClose: () => console.log('closing'),
            onError: (err) => console.log('error', err),
            onReconnectStop: (err) => console.log('recon stop', err),
        }
    );

    const detectLabelAnalysis = useCallback((imageS3Key) => {
        sendMessage(JSON.stringify({ task: 'detectLabelAnalysis', station: cookies.station, staff: cookies.staff, did: cookies.checkinDeviceId, imageS3Key }))
    }, [sendMessage, cookies]);

    const checkSid = useCallback(() => {
        setCheckSidResponse(null);
        sendMessage(JSON.stringify({ task: 'checkSid', station: posConfig.connection.station, staff: cookies.staff, sid: checkinData.sid, spedition: checkinData.spedition }))
    }, [sendMessage, posConfig.connection.station, cookies.staff, checkinData.sid, checkinData.spedition]);

    const checkinShipment = useCallback(() => {
        const { sid, kdnr, klasse, spedition, absender, incomplete, allowDuplicate } = checkinData;
        const task = {
            task: 'checkinShipment',
            station: cookies.station,
            staff: cookies.staff,
            sid,
            kdnr,
            klasse,
            spedition,
            absender,
            photoFileKey: responseS3Key,
            incomplete,
            did: cookies.checkinDeviceId,
            allowDuplicate,
            feedback,
            manualEditedData,
            version: 'v2.0.1'
        };
        sendMessage(JSON.stringify(task))
        //console.log({task})
    }, [sendMessage, cookies, checkinData, responseS3Key, feedback]);

    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg);

        if (msg.task === 'respDetectLabelAnalysis' && msg.status === 'ERROR') {
            console.log('ERROR DETECT LABEL LAMBDA')
        }

        if (msg.task === 'respDetectLabelAnalysis' && msg.status === 'SUCCEEDED') {
            setDetectionResults(msg.detectionResult);
            setDetectedBarcodes(msg.barcodeResult);
            setPendingAnalysis(false);
            if (msg.customer) {
                setCheckinData({ ...checkinData, name: `${msg.customer.firstname} ${msg.customer.lastname}`, kdnr: msg.customer.kdnr })
            }
            console.timeEnd('x')
        }

        if (msg.task === 'respCheckSid') {
            setCheckSidResponse(msg.result);
            if (msg.result.bdv) {
                setCustomerData('absender', `${msg.result.bdv.absender}`.replace(/^\d+\s*/, ''))
            }
        }

        if (msg.task === 'respCheckinShipment' && msg.status === "succeeded") {
            setLastLabel(msg.label);
        }

        if (msg.task === 'respGetShipmentLabel') {
            setLastLabel(msg.label)
        }


    };

    const updateDetection = (element, newValue) => { };

    const sidInputRef = useRef(null);

    useEffect(() => {
        if (sidInputRef.current) {
            sidInputRef.current.focus();
        }
    }, [checkinData])

    useEffect(() => {
        if (checkinData.sid && checkinData.spedition) {
            checkSid();
        }
    }, [checkinData.sid, checkinData.spedition])

    const kdnrInputRef = useRef(null);

    const setCustomerData = (key, value) => {
        setCheckinData({ ...checkinData, [key]: value });
    };

    const containsAll = (arr1, arr2) => arr2.every(val => arr1.includes(val));
    const validation = () => {

        if (checkSidResponse?.duplicate && !checkinData?.allowDuplicate) {
            return false
        }
        return true;
    };

    const presentKeys = Object.keys(checkinData);
    const expectedKeys = ["name", "kdnr", "sid", "spedition", "klasse", "absender"];
    const submittable = containsAll(presentKeys, expectedKeys) && validation();

    const handleSubmit = () => {
        if (submittable) {
            checkinShipment();
        } else {
            console.log({ presentKeys, expectedKeys })
        }
    };

    useEffect(() => {
        if (checkinData.spedition === 'DPD' && checkinData.sid && checkinData.sid?.match(/^%0079713/) !== null) {
            const newSid = checkinData.sid.replace(/^%0079713/, '');
            setCustomerData('sid', newSid);
        }

        if (checkinData.spedition === 'GLS' && checkinData.sid && checkinData.sid?.length > 30) {
            const newSid = /^.{9}\s.{23}([YZ][A-Z0-9a-z]{7})/g.exec(checkinData.sid)?.[1];
            if (newSid?.length === 8) {
                setCustomerData('sid', newSid);
            }
        }

        if (checkinData.spedition === "Amazon" && !checkinData.absender) {
            setCustomerData('absender', 'Amazon');
        }
    }, [checkinData.sid, checkinData.spedition]);

    useEffect(() => {
        if (checkinData.spedition !== 'Amazon' && checkinData.absender === 'Amazon') {
            setCustomerData('absender', undefined);
        }
    }, [checkinData.spedition]);

    useEffect(() => {
        if (!previewImage) {
            capture();
        }
    }, [checkinData.klasse]);

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    useEffect(() => {
        if (lastLabel) {
            handlePrint();
            reset();
        }
    }, [lastLabel])

    useEffect(() => {
        if(lastLabel){
            setLastLabel(null);
        }
    }, [checkinData]);


    useEffect(()=>{
        
        if(!checkinData.absender){
            
            const lines = detectionResults?.ocrResponse?.TextDetections?.filter(e=>e.Type === 'LINE') || [];

            let matches = [];
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                const match = line?.DetectedText?.match(/^(von|abs|absender|from|sender)([\s.:\/-]+(von|abs|absender|from|sender))*[\s.:\/-]+(.+)/i);
                console.log({match})
                if (match) {
                    matches.push(match[4].trim());
                }
                
            }
            console.log({matches})
            if(matches.length === 1){
                setCustomerData('absender', matches[0]);
            }
        }

    },[checkinData.absender, detectionResults])


    useEffect(()=>{
        if(!checkinData.sid && detectedBarcodes && checkinData.spedition){
            let selectedSpeditionMatches = [];            
            for(let barcode of detectedBarcodes){
                const companies = predictDeliveryCompany(barcode);
                if(companies.includes(checkinData.spedition)){
                    selectedSpeditionMatches.push(barcode)
                }
            }
            if([...new Set(selectedSpeditionMatches)].length === 1){
                setCustomerData('sid', selectedSpeditionMatches[0]);
            }
        }
        if(! checkinData.sid && checkinData.spedition === 'Amazon'){

            const lines = detectionResults?.ocrResponse?.TextDetections || [];

            let matches = [];
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                const match = line?.DetectedText?.match(/^DE[0-9]{9,10}$/);
                if (match) {
                    matches.push(match[0].trim());
                }
                
            }
            if([...new Set(matches)].length === 1){
                setCustomerData('sid', matches[0]);
            }
        }
    },[detectedBarcodes, detectionResults, checkinData.spedition, checkinData.sid]);


    const predictDeliveryCompany = (shipmentNumber) => {

        const rules = [
            { name: 'Hermes', rule: /^(3S[A-Z0-9]{11}|[0-9]{14}|H[0-9]{19})$/ },
            { name: 'Trans-o-Flex', rule: /^[0-9]{24}$/ },
            { name: 'FedEx', rule: /^([0-9]{28}|[0-9]{34})$/ },
            { name: 'Post', rule: /^0034[0-9]{16}$/ },
            { name: 'DPD', rule: /^[0-9]{15}$/ },
            { name: 'Post', rule: /^([A-F0-9]{20}|[A-Z0-9]{11}[A-Z]{2})$/ },
            { name: 'GLS', rule: /^([0-9]{12}|[YZ][A-Z0-9]{7})$/ },
            { name: 'UPS', rule: /^1Z[A-Z0-9]{16}$/ },
            { name: 'Amazon', rule: /^DE[0-9]{9,10}$/ },
            { name: 'DHL Express', rule: /^[0-9]{10}$/ },
            { name: 'DHL', rule: /^([0-9]{12}|JVGL.+|0034.+|\(00\)34.+|JJ.+)$/ }
        ];

        let results = [];
        for (let i = 0; i < rules.length; i++) {
            const company = rules[i].name;
            const regex = rules[i].rule;
            if (regex.test(shipmentNumber)) {
                results.push(company);
            }
        };

        return results;
    }

    return (
        <Box>
            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                <Card variant='outlined' sx={{ borderColor: checkinData.spedition ? 'green' : 'red' }}>
                    <SpeditionSelector onChange={(x) => setCustomerData('spedition', x)} value={checkinData.spedition} />
                </Card>
                <Card variant='outlined' sx={{ flex: 6, borderColor: checkinData.klasse ? 'green' : 'red' }}>
                    <ShipmentClass value={checkinData.klasse} onChange={(val) => setCustomerData('klasse', val)} />
                </Card>

                <Box sx={{ position: "relative", overflow: "hidden", height: "110px" }}>
                    {/* First Slide */}
                    <Slide direction="down" in={mainScreen === 'OVERVIEW'} timeout={200}>
                        <Box sx={{ display: "flex", gap: 1, position: "absolute", width: "100%" }}>

                            <Card variant="outlined" sx={{ p: 1, flex: 8, width: "170px", alignContent: 'center', borderColor: checkinData.sid ? 'green' : 'red' }}>
                                <Box sx={{ flex: 1, alignContent: 'center' }}>
                                    {!checkinData.sid &&
                                        <SidScannerField sidInputRef={sidInputRef} onSelect={(val) => { setManualEditedData({...manualEditedData, sid: true}); setCustomerData('sid', val) }} />
                                    }
                                    {checkinData.sid && <>
                                        <Typography variant="h6">
                                            Sendungsnummer: {checkinData.sid}
                                            <IconButton onClick={() => setCustomerData('sid', undefined)}>
                                                <Cancel />
                                            </IconButton>
                                        </Typography>
                                    </>}
                                </Box>
                                {checkSidResponse?.duplicate && <Alert severity='warning'>Diese Sendungsnummer ist bereits vorhanden! <Checkbox onClick={(evt) => {
                                    setCustomerData('allowDuplicate', evt.target.checked);
                                }} sx={{ p: 0 }} /> Erlaube Duplikat</Alert>}
                            </Card>
                            <Card variant="outlined" sx={{ p: 1, width: "170px", borderColor: checkinData.kdnr ? 'green' : 'red' }} >
                                <CardActionArea onClick={() => {
                                    setManualEditedData({...manualEditedData,kdnr: true})
                                    setMainScreen('CUSTOMER');
                                }}>
                                    <Typography variant="h6">Kunde: {checkinData.kdnr}</Typography>
                                    {!checkinData.kdnr && <Typography>Kunde auswählen</Typography>}
                                    <Typography variant="body2">{checkinData.name}</Typography>
                                </CardActionArea>
                            </Card>
                            <Card variant="outlined" sx={{ p: 1, width: "170px", borderColor: checkinData.absender ? 'green' : 'red' }}>
                                <CardActionArea onClick={() => {
                                    setManualEditedData({...manualEditedData, senderField: true});
                                    setMainScreen('SENDER');
                                }}>
                                    <Box display={"flex"}>
                                        <Typography variant="h6">Absender: </Typography>
                                        {checkSidResponse?.bdv &&
                                            <Chip label="BDV" color="success" size='small' sx={{ mx: 1 }} />
                                        }
                                    </Box>

                                    <Typography variant="body2">{checkinData.absender}</Typography>
                                </CardActionArea>
                            </Card>
                            <Card variant='outlined' sx={{alignContent: 'center', }}>
                                <Box sx={{m:1,}}>
                                    <Button size='large' disabled={!submittable} onClick={handleSubmit} variant='contained' color='success'>Erfassen &gt;&gt;</Button>
                                </Box>
                            </Card>

                        </Box>
                    </Slide>

                    {/* Second Slide */}
                    <Slide direction="up" in={mainScreen === 'CUSTOMER'} timeout={200}>
                        <Card variant="outlined" sx={{ display: "flex", position: "absolute", width: "100%", p: 1 }}>
                            <Box sx={{ display: 'flex' }}>
                                <Button onClick={() => setMainScreen('OVERVIEW')}>Zurück</Button>
                                <SearchField kdnrInputRef={kdnrInputRef} PaperComponent={Box} paperSx={{ pt: 2, pb: 2, width: '500px' }} onSelect={(obj) => { setCheckinData({ ...checkinData, name: obj.name, kdnr: obj.id, override: true }); setMainScreen("OVERVIEW") }} posConfig={posConfig} />
                            </Box>
                        </Card>
                    </Slide>
                    <Slide direction="up" in={mainScreen === 'SENDER'} timeout={200}>
                        <Card variant="outlined" sx={{ display: "flex", position: "absolute", width: "100%", p: 1 }}>
                            <Box sx={{ display: 'flex' }}>
                                <Button onClick={() => setMainScreen('OVERVIEW')}>Zurück</Button>
                                <TextField
                                    label="Absender"
                                    onKeyDown={(evt) => {
                                        console.log({ evt })
                                        if (evt.keyCode === 13) {
                                            setMainScreen("OVERVIEW")
                                        }
                                    }}
                                    onChange={(evt) => { setCustomerData('absender', evt.target.value) }}
                                    value={checkinData.absender || ""}
                                />
                            </Box>
                        </Card>
                    </Slide>
                </Box>


                <Box sx={{ maxWidth: '1600px' }}>


                    {responseImage &&
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    display: pendingAnalysis ? 'block' : 'none'
                                }}>
                                <CircularProgress
                                    size={100}
                                />
                                <Box sx={{ p: 1, backgroundColor: 'rgb(200,200,200,0.9)' }}>
                                    <Typography variant="h6">Analyse läuft</Typography>
                                </Box>
                            </Box>
                            <ImageWithPolygons feedback={feedback} setFeedback={setFeedback} imageUrl={responseImage} ocrResponse={detectionResults?.ocrResponse} updateDetection={updateDetection} setCustomerData={setCustomerData} />

                        </Box>}
                    {responseImage === null && previewImage &&
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                }}
                            >
                                <CircularProgress
                                    size={100}
                                />
                                <Box sx={{ p: 1, backgroundColor: 'rgb(200,200,200,0.9)' }}>
                                    <Typography variant="h6">Upload läuft</Typography>
                                </Box>
                            </Box>
                            <Box
                                component="img"
                                sx={{ width: '100%' }}
                                src={previewImage}
                                onClick={reset}
                            />
                        </Box>
                    }
                    {!responseImage && !previewImage &&
                    <Webcam
                        audio={false}
                        screenshotFormat="image/png"
                        screenshotQuality={1}
                        imageSmoothing={true}
                        width={"100%"}
                        forceScreenshotSourceSize={true}
                        minScreenshotHeight={2160}
                        minScreenshotWidth={3840}
                        videoConstraints={{
                            width: 3840,
                            height: 2160,
                            facingMode: "user"
                        }}
                        ref={webcamRef}
                        onClick={capture}
                    />
                }
                {/*<CheckinAutoVideoCapture webcamRef={webcamRef} capture={capture} />*/}
                </Box>

            </Box>
            <LabelPreview componentRef={printRef} label={lastLabel} handlePrint={handlePrint} />
            <LastLabels />
            <Button onClick={reset}>Reset</Button>
            <Button onClick={capture}>Capture</Button>
        </Box>
    );
};

const LastLabels = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer variant="temporary" anchor="right" open={open} onClose={() => { setOpen(false) }}>
                <Box p={4}>
                    <Paper sx={{ p: 2, minWidth: '280px' }}>
                        <Typography>Letzte Labels</Typography>
                        <LastShipments onClose={() => { setOpen(false) }} />
                    </Paper>
                </Box>
            </Drawer>
            <Fab variant="extended" sx={{ position: 'absolute', right: 20, bottom: 20 }} onClick={() => { setOpen(true) }}>
                <History sx={{ mr: 1 }} />
                Letzte Labels
            </Fab>
        </>

    );
};


const PhotoCapture = ({ webcamRef }) => {

};




const SpeditionSelector = ({ value, predictedSpedition, onChange }) => {

    const values = [
        { name: 'UPS', img: { src: upsLogo } },
        { name: 'DHL Express', img: { src: dhlexpressLogo } },
        { name: 'DHL', img: { src: dhlLogo } },
        { name: 'Hermes', img: { src: hermesLogo } },
        { name: 'DPD', img: { src: dpdLogo } },
        { name: 'Amazon', img: { src: amazonLogo } },
        { name: 'Post', img: { src: postLogo } },
        { name: 'FedEx', img: { src: fedexLogo } },
        { name: 'GLS', img: { src: glsLogo } },
        { name: 'Trans-o-flex', img: { src: transoflexLogo } },
        { name: 'Spedition' },
    ];

    return (
        <>
            <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0, p: 1 }}>
                {
                    values.map((e, i) => {

                        const border = e.name === value ? 'solid 8px blue' : 'solid 8px white';

                        return (
                            <Box key={i} onClick={() => onChange(e.name)} sx={{ minWidth: '100px', textAlign: 'center', border, p: 0.5 }}>
                                {e.img && <Box sx={{ height: '25px' }} component="img" src={e.img.src} />}
                                {!e?.img && <Typography variant="h6" sx={{ height: '25px' }}>{e.name}</Typography>}
                            </Box>
                        );
                    })
                }
            </Box>

            {predictedSpedition !== null && !!value && predictedSpedition?.includes(value) === false && <Alert sx={{ mb: 2 }} severity="warning">Sendungsnummer-Format entspricht nicht dem Norm-Format. Speditions-Auswahl überprüfen!</Alert>}
        </>
    );
}



const ShipmentClass = ({ value, onChange }) => {

    return (
        <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', p: 1 }}>
            {
                ["B", "VT", "S", "M", "L", "XL", "XXL", "G1", "G2", "G3", "P1", "P2", "P3"].map((e, i) => {

                    const border = e === value ? 'solid 8px blue' : 'solid 8px white';

                    return (
                        <Box key={i} onClick={() => onChange(e)} sx={{ minWidth: '70px', textAlign: 'center', border, p: 0.5 }}><Typography>{e}</Typography></Box>
                    );
                })
            }
        </Box>
    );
};


