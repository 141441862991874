import React, { useRef, useState } from 'react';

function WebRTCVideo() {
  const videoRef = useRef(null);
  const [peerConnection, setPeerConnection] = useState(null);

  async function startWebRTC() {
    const pc = new RTCPeerConnection();
    setPeerConnection(pc);

    pc.ontrack = (event) => {
      if (videoRef.current) {
        videoRef.current.srcObject = event.streams[0];
      }
    };

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const response = await fetch('http://localhost:8080/offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sdp: pc.localDescription.sdp,
        type: pc.localDescription.type,
      }),
    });

    const { sdp, type } = await response.json();
    const remoteDesc = new RTCSessionDescription({ sdp, type });
    await pc.setRemoteDescription(remoteDesc);
  }

  return (
    <div>
      <button onClick={startWebRTC}>Start Stream</button>
      <video ref={videoRef} autoPlay playsInline controls />
    </div>
  );
}

export default WebRTCVideo;